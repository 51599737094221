<template>
  <div>
    <a-button icon="left" @click="back" v-if="step!==2">返回</a-button>
    <div class="gw-80 g-center gmt-40">
      <a-steps :current="step" size="small" class="gmb-60">
        <a-step title="验证身份" />
        <a-step title="手机换绑" />
        <a-step title="绑定成功" />
      </a-steps>
      <div class="gw-400p g-center">
        <!-- 第一步 -->
        <a-form-model :model="oldForm" v-if="step==0">
          <a-form-model-item>
            <div class="g-flex gb-eee">
              <div class="gh-40p gw-100p g-flex g-flex-c gbd" style="border-right:0;">
                原手机号
              </div>
              <div class="gh-40p g-flex-1 g-flex g-align-center gbd gpl-20">
                {{oldForm.mobile}}
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item>
            <div class="g-flex g-justify-space-between">
              <a-input v-model="oldForm.code" class="gmr-20" placeholder="请输入验证码" size="large" />
              <a-button size="large" v-if="timeNum==0" @click="getCodeToOldMobile" :disabled="loading">获取验证码</a-button>
              <div class="gw-110p" v-else>{{timeNum}}</div>
            </div>
            <span class="gc-red">{{tipMsg}}</span>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" block size="large" @click="next">下一步</a-button>
          </a-form-model-item>
        </a-form-model>
        <!-- 第二步 -->
        <a-form-model :model="oldForm" v-if="step==1">
          <a-form-model-item>
            <a-input-group compact>
              <a-select default-value="86" style="width: 20%" size="large">
                <a-select-option value="86">
                  +86
                </a-select-option>
              </a-select>
              <a-input style="width: 80%" v-model="newForm.mobile" size="large" placeholder="请输入新手机" />
            </a-input-group>
          </a-form-model-item>
          <a-form-model-item>
            <div class="g-flex g-justify-space-between">
              <a-input v-model="newForm.code" class="gmr-20" placeholder="请输入验证码" size="large" />
              <a-button size="large" v-if="timeNum==0" @click="getCodeToNewMobile" :disabled="loading">获取验证码</a-button>
              <div class="gw-110p" v-else>{{timeNum}}</div>
            </div>
            <span class="gc-red">{{tipMsg}}</span>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" block size="large" @click="next">下一步</a-button>
          </a-form-model-item>
        </a-form-model>
        <!-- 第三步 -->
        <div v-if="step==2" class="gtc">
          <div class="gfs-20 gfw-b gmb-40">设置手机号成功！</div>
          <a-button type="primary" size="large" style="width:200px" @click="back">返回首页</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCodeToMobile, checkCode, updateMobile } from "@/api/login";
export default {
  data() {
    return {
      step: 0,
      oldForm: {
        mobile: "",
        code: "",
      },
      newForm: {
        mobile: "",
        code: "",
      },
      tipMsg: "",
      timeNum: 0,
      timeId: "",
      loading: false,
    };
  },
  beforeDestroy() {
    clearInterval(this.timeId);
  },
  created() {
    this.oldForm.mobile = this.$store.getters.userInfo.mobile;
  },
  methods: {
    //发送验证码
    getCodeToMobile(mobile) {
      this.loading = true;
      getCodeToMobile(mobile).then((res) => {
        if (res.code == 200) {
          this.timeNum = 60;
          this.timeId = setInterval(() => {
            this.timeNum--;
            if (this.timeNum == 0) {
              clearInterval(this.timeId);
            }
          }, 1000);
        } else {
          this.tipMsg = res.msg;
        }
        this.loading = false;
      });
    },
    //旧手机发送验证码
    getCodeToOldMobile() {
      this.getCodeToMobile(this.oldForm.mobile);
    },
    //新手机发送验证码
    getCodeToNewMobile() {
      this.getCodeToMobile(this.newForm.mobile);
    },
    next() {
      if (this.step == 0) {
        if (!this.oldForm.code) {
          this.tipMsg = "请输入验证码";
          return;
        }
        checkCode(this.oldForm).then((res) => {
          if (res.code == 200) {
            this.step++;
            this.tipMsg = "";
            this.timeNum = 0;
            clearInterval(this.timeId);
          } else {
            this.tipMsg = res.msg;
          }
        });
      } else if (this.step == 1) {
        if (!this.newForm.mobile) {
          this.tipMsg = "请输入新的手机号";
          return;
        }
        if (!this.newForm.code) {
          this.tipMsg = "请输入验证码";
          return;
        }
        updateMobile(this.newForm).then((res) => {
          if (res.code == 200) {
            this.step++;
            this.tipMsg = "";
            this.timeNum = 0;
            clearInterval(this.timeId);
          } else {
            this.tipMsg = res.msg;
          }
        });
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>